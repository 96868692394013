var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "skeleton-post-wrapper" },
    [
      _c("skeleton-loader-vue", {
        attrs: { width: "100%", height: "179px", animation: "fade" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "skeleton-content" },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center mt-2" },
            [
              _c("skeleton-loader-vue", {
                staticClass: "rounded-circle flex-shrink-0",
                attrs: {
                  width: "28px",
                  height: "28px",
                  animation: "fade",
                  rounded: true,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex flex-column w-100 ml-1" },
                [
                  _c("skeleton-loader-vue", {
                    attrs: {
                      width: "70%",
                      height: "13px",
                      animation: "fade",
                      rounded: true,
                    },
                  }),
                  _vm._v(" "),
                  _c("skeleton-loader-vue", {
                    staticClass: "mt-1",
                    attrs: {
                      width: "40%",
                      height: "11px",
                      animation: "fade",
                      rounded: true,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "line-seperate" }),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { clas: "w-100" } },
            [
              _c("skeleton-loader-vue", {
                attrs: {
                  width: "90%",
                  height: "14px",
                  animation: "fade",
                  rounded: true,
                },
              }),
              _vm._v(" "),
              _c("skeleton-loader-vue", {
                staticClass: "mt-1",
                attrs: {
                  width: "90%",
                  height: "14px",
                  animation: "fade",
                  rounded: true,
                },
              }),
              _vm._v(" "),
              _c("skeleton-loader-vue", {
                staticClass: "mt-1",
                attrs: {
                  width: "60%",
                  height: "14px",
                  animation: "fade",
                  rounded: true,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("skeleton-loader-vue", {
            staticClass: "mt-2 rounded-24",
            attrs: { width: "100px", height: "24px", animation: "fade" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }