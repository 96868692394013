var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: () => (_vm.isShowMenu = false),
          expression: "() => isShowMenu = false",
        },
      ],
      staticClass: "menu-post-wrapper",
    },
    [
      _c(
        "div",
        {
          staticClass: "trigger",
          on: {
            click: function ($event) {
              _vm.isShowMenu = !_vm.isShowMenu
            },
          },
        },
        [_vm._v("Menu")]
      ),
      _vm._v(" "),
      _vm.isShowMenu
        ? _c(
            "div",
            { staticClass: "menu-dropdown" },
            [
              _c(
                "nuxt-link",
                {
                  staticClass: "menu-dropdown__item",
                  class: { actived: !_vm.currentCategory.id },
                  attrs: { to: _vm.routeHashBaseOnTab("ALL") },
                  nativeOn: {
                    click: function ($event) {
                      _vm.isShowMenu = false
                    },
                  },
                },
                [
                  _c("span", { staticClass: "truncate-1" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("common.all")) + "\n      "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.postCategories, function (postCategory) {
                return _c(
                  "nuxt-link",
                  {
                    key: postCategory.id,
                    staticClass: "menu-dropdown__item",
                    class: {
                      actived: _vm.currentCategory.id === postCategory.id,
                    },
                    attrs: {
                      to: _vm.routeHashBaseOnTab(
                        _vm.findNameByLanguageSetting(postCategory.names, "en")
                      ),
                    },
                    nativeOn: {
                      click: function ($event) {
                        _vm.isShowMenu = false
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "truncate-1" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.getNameCategory(postCategory.id)) +
                          "\n      "
                      ),
                    ]),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }