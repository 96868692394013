
import { Vue, Component, Prop } from "nuxt-property-decorator";
import VueSkeletonLoader from "skeleton-loader-vue";

@Component({
  components: {
    "skeleton-loader-vue": VueSkeletonLoader,
  },
})
export default class SkeletonPostHome extends Vue {}
