
import { Component, Prop, Watch } from "nuxt-property-decorator";
import { PostCategory } from "~/modals";
import { Mixins } from "vue-mixin-decorator";
import Common from "~/mixins/CommonMixins.vue";

@Component({})
export default class MenuCategoryMobile extends Mixins(Common) {
  @Prop({ type: Object, required: true }) postCategories!: PostCategory[];
  @Prop({ type: Object, required: true }) currentCategory!: PostCategory;

  isShowMenu = false;

  routeHashBaseOnTab(nextHash: string) {
    return (this as any).localePath({
      name: "posts",
      hash: "#" + nextHash,
    });
  }

  findNameByLanguageSetting(_arrayInput, tempLang: string) {
    const lang = tempLang ? tempLang : this.$i18n.locale;
    let tmpObject = _arrayInput.find((o1) => o1.language_code === lang);
    if (tmpObject) {
      return tmpObject.name;
    }
    return {
      name: "",
    };
  }

  getNameCategory(id) {
    let obj = this.postCategories
      ?.find((_cat) => _cat.id === id)
      ?.names?.find((_n) => (_n as any).language_code === this.$i18n.locale);
    if (obj) return (obj as any).name;
    return this.postCategories!.find((_cat) => _cat.id === id)?.categoryName;
  }
  // @Watch("currentCategory")
  // onCurrentCategoryChange() {
  //   this.isShowMenu = false;
  // }
}
