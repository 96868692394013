var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex-column bg-wrapper post-wrapper" },
    [
      _c("ClientOnly", [
        _c("section", { staticClass: "menu-mobile" }, [
          _c(
            "div",
            { staticClass: "menu-mobile__content container p-0 m-0" },
            [
              _c("MenuCategoryMobile", {
                attrs: {
                  currentCategory: _vm.currentCategory,
                  postCategories: _vm.postCategories,
                },
              }),
              _vm._v(" "),
              !_vm.isLoading
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !(_vm.currentTab === "ALL"),
                          expression: "!(currentTab === 'ALL')",
                        },
                      ],
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !(
                                _vm.currentTab === "ALL" && _vm.posts.length < 5
                              ),
                              expression:
                                "!(currentTab === 'ALL' && posts.length < 5)",
                            },
                          ],
                          staticClass: "menu-mobile__content__title",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.currentTab == "ALL"
                                ? _vm.$t("article.all_article")
                                : _vm
                                    .$t("article.list_article_by_category")
                                    .toString()
                                    .replace(
                                      "{0}",
                                      _vm.getNameCategory(
                                        _vm.currentCategory.id
                                      )
                                    )
                            )
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentTab !== "ALL"
                ? _c(
                    "div",
                    { staticClass: "carousel-inner" },
                    [
                      _vm.posts[0]
                        ? _c(
                            "nuxt-link",
                            {
                              staticClass: "carousel-item active",
                              attrs: { to: _vm.onGoToPostDetail(_vm.posts[0]) },
                            },
                            [
                              _c("div", {
                                staticClass: "img",
                                style: {
                                  background:
                                    "linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%), url(" +
                                    _vm.posts[0].thumbnailUrl +
                                    "), #C4C4C4",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "carousel-caption padding-y-10",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "category-name mt-2" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.getNameCategory(
                                              _vm.posts[0].categoryId
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.posts[0].title)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "tippy",
                                          rawName: "v-tippy",
                                          value: {
                                            delay: [1000, 200],
                                            placement: "bottom-start",
                                            animateFill: false,
                                            animation: "scale",
                                            zIndex: _vm.textContent ? -1 : 9999,
                                          },
                                          expression:
                                            "{\n                  delay: [1000, 200],\n                  placement: 'bottom-start',\n                  animateFill: false,\n                  animation: 'scale',\n                  zIndex: textContent ? -1 : 9999,\n                }",
                                        },
                                      ],
                                      staticClass: "text-date",
                                      attrs: {
                                        id:
                                          "time-main-posts-" + _vm.posts[0].id,
                                        content: `<span style='word-break: break-word;white-space:pre-line;word-wrap: break-word; display: block;'>${_vm.formatDateTimeCommon(
                                          _vm.posts[0].publishedAt
                                        )}</span>`,
                                      },
                                      on: {
                                        contextmenu: function ($event) {
                                          _vm.textContent = ""
                                        },
                                        mouseover: function ($event) {
                                          _vm.textContent = _vm.$handleHover(
                                            `time-main-posts-${_vm.posts[0].id}`,
                                            _vm.posts[0].title,
                                            2,
                                            22,
                                            0,
                                            null
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.displayCreateTime(
                                              _vm.posts[0].publishedAt
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("posts-right", {
                attrs: {
                  getCategoryName: _vm.getNameCategory,
                  isTabAll: _vm.currentTab == "ALL",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass:
            "d-flex justify-content-center align-items-center pb-4 flex-column w-100 main-wrapper",
          class: [{ "pt-0": _vm.$device.isMobile }],
        },
        [
          _c("div", { staticClass: "post-container container m-0 p-0" }, [
            _c("div", { staticClass: "nav-left-wrapper" }, [
              _c(
                "div",
                { staticClass: "nav-left" },
                [
                  _c("span", { staticClass: "nav-title" }, [_vm._v("Menu")]),
                  _vm._v(" "),
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "option",
                      class: { actived: !_vm.currentCategory.id },
                      attrs: { to: _vm.routeHashBaseOnTab("ALL") },
                    },
                    [
                      _c("span", { staticClass: "truncate-1" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("common.all")) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.postCategories, function (postCategory) {
                    return _c(
                      "nuxt-link",
                      {
                        key: postCategory.id,
                        staticClass: "option",
                        class: {
                          actived: _vm.currentCategory.id === postCategory.id,
                        },
                        attrs: {
                          to: _vm.routeHashBaseOnTab(
                            _vm.findNameByLanguageSetting(
                              postCategory.names,
                              "en"
                            )
                          ),
                        },
                      },
                      [
                        _c("span", { staticClass: "truncate-1" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getNameCategory(postCategory.id)) +
                              "\n            "
                          ),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "px-0 w-100",
                class: _vm.$device.isMobile ? "posts-mobile" : null,
              },
              [
                !_vm.isLoading
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !(_vm.currentTab === "ALL"),
                            expression: "!(currentTab === 'ALL')",
                          },
                        ],
                        staticClass: "banner-post-category",
                        style: {
                          "margin-bottom":
                            _vm.currentWidthWindow > 576 ? "9px" : "16px",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !(
                                  _vm.currentTab === "ALL" &&
                                  _vm.posts.length < 5
                                ),
                                expression:
                                  "!(currentTab === 'ALL' && posts.length < 5)",
                              },
                            ],
                            staticClass: "_title",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.currentTab == "ALL"
                                  ? _vm.$t("article.all_article")
                                  : _vm
                                      .$t("article.list_article_by_category")
                                      .toString()
                                      .replace(
                                        "{0}",
                                        _vm.getNameCategory(
                                          _vm.currentCategory.id
                                        )
                                      )
                              )
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentTab === "ALL"
                  ? _c(
                      "div",
                      { staticClass: "carousel-inner mb-4" },
                      [
                        _vm.newPosts[0]
                          ? _c(
                              "nuxt-link",
                              {
                                staticClass: "carousel-item active",
                                attrs: {
                                  to: _vm.onGoToPostDetail(_vm.newPosts[0]),
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "img",
                                  style: {
                                    background:
                                      "linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%), url(" +
                                      _vm.newPosts[0].thumbnailUrl +
                                      "), #C4C4C4",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "carousel-caption padding-y-10",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "category-name mt-2" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.getNameCategory(
                                                _vm.newPosts[0].categoryId
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.newPosts[0].title)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "tippy",
                                            rawName: "v-tippy",
                                            value: {
                                              delay: [1000, 200],
                                              placement: "bottom-start",
                                              animateFill: false,
                                              animation: "scale",
                                              zIndex: _vm.textContent
                                                ? -1
                                                : 9999,
                                            },
                                            expression:
                                              "{\n                  delay: [1000, 200],\n                  placement: 'bottom-start',\n                  animateFill: false,\n                  animation: 'scale',\n                  zIndex: textContent ? -1 : 9999,\n                }",
                                          },
                                        ],
                                        staticClass: "text-date",
                                        attrs: {
                                          id:
                                            "time-main-posts-" +
                                            _vm.newPosts[0].id,
                                          content: `<span style='word-break: break-word;white-space:pre-line;word-wrap: break-word; display: block;'>${_vm.formatDateTimeCommon(
                                            _vm.newPosts[0].publishedAt
                                          )}</span>`,
                                        },
                                        on: {
                                          contextmenu: function ($event) {
                                            _vm.textContent = ""
                                          },
                                          mouseover: function ($event) {
                                            _vm.textContent = _vm.$handleHover(
                                              `time-main-posts-${_vm.newPosts[0].id}`,
                                              _vm.newPosts[0].title,
                                              2,
                                              22,
                                              0,
                                              null
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.displayCreateTime(
                                                _vm.newPosts[0].publishedAt
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("client-only", [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab === "ALL",
                          expression: "currentTab === 'ALL'",
                        },
                      ],
                      staticClass: "carousel-wrapper",
                    },
                    [
                      _c(
                        "carousel",
                        {
                          ref: "carousel",
                          attrs: {
                            paginationEnabled: false,
                            navigationEnabled: "",
                            navigationClickTargetSize: 0,
                            loop: "",
                            navigationNextLabel:
                              '<svg\n                width="8"\n                height="14"\n                viewBox="0 0 8 14"\n                fill="none"\n                xmlns="http://www.w3.org/2000/svg"\n              >\n                <path\n                  d="M1 13L7 7L1 1"\n                  stroke="#000"\n                  stroke-width="2"\n                  stroke-linecap="round"\n                  stroke-linejoin="round"\n                />\n              </svg>',
                            navigationPrevLabel:
                              '<svg\n                xmlns="http://www.w3.org/2000/svg"\n                width="24"\n                height="24"\n                viewBox="0 0 24 24"\n                fill="none"\n              >\n                <path\n                  d="M15 18L9 12L15 6"\n                  stroke="#000"\n                  stroke-width="2"\n                  stroke-linecap="round"\n                  stroke-linejoin="round"\n                />\n              </svg>',
                          },
                        },
                        _vm._l(
                          _vm.newPosts.slice(1),
                          function (newPost, index) {
                            return _c("slide", { key: index }, [
                              _c(
                                "div",
                                {
                                  class: {
                                    "pr-2": index % 2 == 0,
                                    "pl-2": index % 2 != 0,
                                  },
                                },
                                [
                                  _c("new-post-item", {
                                    attrs: { post: newPost },
                                  }),
                                ],
                                1
                              ),
                            ])
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "all-article",
                    staticClass: "m-0 px-0 block-box",
                    class: [
                      { "gap-24": _vm.currentTab == "ALL" },
                      { "pb-1": _vm.$isIE },
                      // { 'check-border-top': currentTab == 'ALL' },
                      // { 'mt-2': currentWidthWindow < 768.1 },
                    ],
                  },
                  [
                    _vm.currentTab != "ALL"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "carousel-inner mb-4 banner-post-category",
                          },
                          [
                            _vm.posts[0]
                              ? _c(
                                  "nuxt-link",
                                  {
                                    staticClass: "carousel-item active",
                                    attrs: {
                                      to: _vm.onGoToPostDetail(_vm.posts[0]),
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "img",
                                      class: { "h-240": true },
                                      style: {
                                        background:
                                          "linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%), url(" +
                                          _vm.posts[0].thumbnailUrl +
                                          "), #C4C4C4",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "carousel-caption" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "category-name mt-2" },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.getNameCategory(
                                                    _vm.posts[0].categoryId
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.posts[0].title)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "tippy",
                                                rawName: "v-tippy",
                                                value: {
                                                  delay: [1000, 200],
                                                  placement: "bottom-start",
                                                  animateFill: false,
                                                  animation: "scale",
                                                  zIndex: _vm.textContent
                                                    ? -1
                                                    : 9999,
                                                },
                                                expression:
                                                  "{\n                    delay: [1000, 200],\n                    placement: 'bottom-start',\n                    animateFill: false,\n                    animation: 'scale',\n                    zIndex: textContent ? -1 : 9999,\n                  }",
                                              },
                                            ],
                                            staticClass: "text-date",
                                            attrs: {
                                              id:
                                                "time-main-posts-" +
                                                _vm.posts[0].id,
                                              content: `<span style='word-break: break-word;white-space:pre-line;word-wrap: break-word; display: block;'>${_vm.formatDateTimeCommon(
                                                _vm.posts[0].publishedAt
                                              )}</span>`,
                                            },
                                            on: {
                                              contextmenu: function ($event) {
                                                _vm.textContent = ""
                                              },
                                              mouseover: function ($event) {
                                                _vm.textContent =
                                                  _vm.$handleHover(
                                                    `time-main-posts-${_vm.posts[0].id}`,
                                                    _vm.posts[0].title,
                                                    2,
                                                    22,
                                                    0,
                                                    null
                                                  )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.displayCreateTime(
                                                    _vm.posts[0].publishedAt
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("client-only", [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isLoading,
                              expression: "isLoading",
                            },
                          ],
                          staticClass: "skeletons-wrapper",
                        },
                        _vm._l(10, function (i) {
                          return _c("SkeletonPostHome", {
                            key: i,
                            staticClass: "post-item",
                          })
                        }),
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    !_vm.isLoading && _vm.currentTab != "ALL"
                      ? [
                          _c(
                            "div",
                            { staticClass: "list-posts" },
                            _vm._l(_vm.posts, function (post, idx) {
                              return _c("new-post-item", {
                                key: post.id,
                                attrs: {
                                  post: post,
                                  isShowAuthor: _vm.currentTab == "ALL",
                                },
                              })
                            }),
                            1
                          ),
                        ]
                      : _vm._l(
                          _vm.postsEveryCategory,
                          function (postsEveryCate, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "category-posts-section",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "category-posts-section__head",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "title-category" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getNameCategory(
                                              postsEveryCate.posts[0].categoryId
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "nuxt-link",
                                      {
                                        staticClass:
                                          "d-flex button-create-post",
                                        attrs: {
                                          to: _vm.routeHashBaseOnTab(
                                            _vm.findNameByLanguageSetting(
                                              postsEveryCate.category.names,
                                              "en"
                                            )
                                          ),
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("common.see_more"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("IcMoveRight"),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "carousel-inner" },
                                  [
                                    _c(
                                      "nuxt-link",
                                      {
                                        staticClass: "carousel-item active",
                                        attrs: {
                                          to: _vm.onGoToPostDetail(
                                            postsEveryCate.posts[0]
                                          ),
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "img",
                                          class: { "h-240": true },
                                          style: {
                                            background:
                                              "linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%), url(" +
                                              postsEveryCate.posts[0]
                                                .thumbnailUrl +
                                              "), #C4C4C4",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "carousel-caption" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "category-name mt-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.getNameCategory(
                                                        postsEveryCate.posts[0]
                                                          .categoryId
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  postsEveryCate.posts[0].title
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "tippy",
                                                    rawName: "v-tippy",
                                                    value: {
                                                      delay: [1000, 200],
                                                      placement: "bottom-start",
                                                      animateFill: false,
                                                      animation: "scale",
                                                      zIndex: _vm.textContent
                                                        ? -1
                                                        : 9999,
                                                    },
                                                    expression:
                                                      "{\n                        delay: [1000, 200],\n                        placement: 'bottom-start',\n                        animateFill: false,\n                        animation: 'scale',\n                        zIndex: textContent ? -1 : 9999,\n                      }",
                                                  },
                                                ],
                                                staticClass: "text-date",
                                                attrs: {
                                                  id:
                                                    "time-main-posts-" +
                                                    postsEveryCate.posts[0].id,
                                                  content: `<span style='word-break: break-word;white-space:pre-line;word-wrap: break-word; display: block;'>${_vm.formatDateTimeCommon(
                                                    postsEveryCate.posts[0]
                                                      .publishedAt
                                                  )}</span>`,
                                                },
                                                on: {
                                                  contextmenu: function (
                                                    $event
                                                  ) {
                                                    _vm.textContent = ""
                                                  },
                                                  mouseover: function ($event) {
                                                    _vm.textContent =
                                                      _vm.$handleHover(
                                                        `time-main-posts-${postsEveryCate.posts[0].id}`,
                                                        postsEveryCate.posts[0]
                                                          .title,
                                                        2,
                                                        22,
                                                        0,
                                                        null
                                                      )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.displayCreateTime(
                                                        postsEveryCate.posts[0]
                                                          .publishedAt
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                postsEveryCate.posts.slice(1)?.length
                                  ? _c(
                                      "div",
                                      { staticClass: "list-posts" },
                                      _vm._l(
                                        postsEveryCate.posts.slice(1),
                                        function (post, idx) {
                                          return _c("new-post-item", {
                                            key: post.id,
                                            attrs: {
                                              post: post,
                                              isShowAuthor:
                                                _vm.currentTab == "ALL",
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                    _vm._v(" "),
                    _vm.posts.length == 0 && !_vm.isLoading
                      ? _c(
                          "div",
                          { staticClass: "no-result mt-2 py-5 text-center" },
                          [
                            _vm._m(0),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("article.no_article_msg"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentTab
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            staticClass: "mb-3",
                            class: { "border-pagination": _vm.posts.length },
                            staticStyle: { "padding-top": "12px" },
                          },
                          [
                            _c(
                              "client-only",
                              [
                                _c("vue-pagination", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.posts.length,
                                      expression: "posts.length",
                                    },
                                  ],
                                  attrs: {
                                    baseUrl: _vm.currenBaseUrl,
                                    optionParam: _vm.optionParam,
                                    paging: 10,
                                  },
                                  on: {
                                    "before-fetch": function ($event) {
                                      _vm.isLoading = $event
                                    },
                                    "fetch-success": _vm.parseDataClass,
                                    "click-page": _vm.scrollToTopPage,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "post-right", class: "pr-md-0" },
              [
                _c("posts-right", {
                  attrs: {
                    getCategoryName: _vm.getNameCategory,
                    isTabAll: _vm.currentTab == "ALL",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "w-100" }, [
      _c("img", {
        staticClass: "py-5",
        attrs: { src: require("assets/icons/icon-not-found.svg"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }