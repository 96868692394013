
import { Vue, Component, Prop, Watch } from "nuxt-property-decorator";
import { plainToClass } from "class-transformer";
import { Post, PostCategory } from "~/modals";
import Common from "~/mixins/CommonMixins.vue";
import { Mixins } from "vue-mixin-decorator";
import { Jsonld } from "nuxt-jsonld";
import VueSkeletonLoader from "skeleton-loader-vue";
import SkeletonPostHome from "~/components/posts/SkelelonPostHome.vue";
import MenuCategoryMobile from "~/components/posts/MenuCategoryMobile.vue";
import IcMoveRight from "~/assets/icons/IcMoveRight.vue";
@Jsonld
@Component({
  scrollToTop: true,
  middleware: ["post-guard"],
  components: {
    PostItem: () => import("~/components/PostItem.vue"),
    PostListItem: () => import("~/components/PostListItem.vue"),
    VuePagination: () => import("~/components/VuePagination.vue"),
    NewPostItem: () => import("~/components/NewPostItem.vue"),
    SkeletonPostListItem: () =>
      import("~/components/skeleton-loading/SkeletonPostListItem.vue"),
    TabsPostCategoryVue: () =>
      import("~/components/posts/TabsPostCategory.vue"),
    PostsRight: () => import("~/components/posts/PostsRight.vue"),
    "skeleton-loader-vue": VueSkeletonLoader,
    SkeletonPostHome,
    MenuCategoryMobile,
    IcMoveRight,
  },
  data: () => ({
    onConfirmYes: () => {},
    onConfirmNo: () => {},
    titleDialog: "",
    contentDialog: "",
    showConfirmDialog: false,
    showErrorAlertModal: false,
  }),
})
export default class ListPost extends Mixins<Common>(Common) {
  head() {
    return {
      title: this.$t("common.hanbook"),
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$t("landing_page.news_slogan"),
        },
      ],
    };
  }
  jsonld() {
    return {
      "@context": "http://schema.org",
      "@type": "WebSite",
      name: this.$t("common.hanbook") + " - GoEMON",
      alternateName: this.$t("landing_page.handbook_desc"),
      url: process.env.BASE_URL + this.$route.fullPath,
    };
  }
  paging?: number = 5;
  page: number = 1;
  pageCategory: number = 1;
  newPosts: Post[] = [];
  posts: Post[] = [];
  postCategories?: PostCategory[];
  currentTab: string = "";
  totalCount: number = 0;
  currentCategory: PostCategory = new PostCategory();
  topArticles?: Post[];
  currenBaseUrl = "";
  optionParam = "";
  isLoading: boolean = true;
  popularHashtags: any;
  textContent: string = "";
  currentWidthWindow = 1200;
  postsEveryCategory: any = [];

  async asyncData({ $axios, params, store, $auth, $i18n }) {
    // let postCategories: PostCategory[] = [];
    let newPosts: any;
    let topArticles: any;
    let popularHashtags: [];
    if ($auth.loggedIn) {
      let results: Object = await Promise.all([
        // $axios.$get(`/api/v1/categories`),
        $axios.$get(`/api/v1/blogs/tops?page=1&paging=5`),
        $axios.$get(
          `/api/v1/hashtags/top?language_code=${$auth.user.support_language.code}`
        ),
        $axios.$get(`/api/v1/blogs?page=1&paging=5`),
      ]);
      // postCategories = JSON.parse(JSON.stringify(results[0]["data"]));
      // (postCategories as any).map(cat => {
      //   let abc = cat.names.find(o1 => o1.language_code === 'en');
      //   if (abc && abc.name) {
      //     cat.categoryName = abc.name
      //   }
      // })
      topArticles = plainToClass(Post, results[0]["data"]);
      popularHashtags = results[1]["data"];
      newPosts = plainToClass(Post, results[2]["data"]);
    } else {
      let results = await Promise.all([
        // $axios.$get(`/api/v1/categories?language_code=${(store as any).$i18n.locale}`),
        $axios.$get(
          `/api/v1/blogs/tops?language_code=${(store as any).$i18n.locale}`
        ),
        $axios.$get(
          `/api/v1/hashtags/top?language_code=${(store as any).$i18n.locale}`
        ),
        $axios.$get(
          `/api/v1/blogs?language_code=${
            (store as any).$i18n.locale
          }&page=1&paging=4`
        ),
      ]);
      // postCategories = JSON.parse(JSON.stringify(results[0]["data"]));
      // (postCategories as any).map(cat => {
      //   let abc = cat.names.find(o1 => o1.language_code === 'en');
      //   if (abc && abc.name) {
      //     cat.categoryName = abc.name
      //   }
      // })
      topArticles = plainToClass(Post, results[0]["data"]);
      popularHashtags = results[1]["data"];
      newPosts = plainToClass(Post, results[2]["data"]);
    }
    return {
      // postCategories,
      topArticles,
      popularHashtags,
      newPosts,
    };
  }
  async fetch({ store }) {
    if (
      !store.state.categoryHashtag.categoryHashtags ||
      (store.state.categoryHashtag.categoryHashtags &&
        store.state.categoryHashtag.categoryHashtags.length == 0)
    ) {
      await store.dispatch("categoryHashtag/fetchList");
    }
    if (
      !store.state.category.categories ||
      (store.state.category.categories &&
        store.state.category.categories.length == 0)
    ) {
      await store.dispatch("category/fetchList");
    }
  }

  async created() {
    this.postCategories = JSON.parse(
      JSON.stringify(this.$store.state.category.categories)
    );
    (this.postCategories as any).map((cat) => {
      let abc = cat.names.find((o1) => o1.language_code === "en");
      if (abc && abc.name) {
        cat.categoryName = abc.name;
      }
    });

    if (this.postCategories && this.postCategories.length) {
      const requests: any = [];
      for (let i = 0; i < this.postCategories.length; i++) {
        requests.push({
          promise: () =>
            this.$axios.$get(
              `api/v1/categories/${this.postCategories[i].id}/blogs?page=1&paging=5`
            ),
        });
      }

      const responses = await Promise.allSettled(
        requests.map((r) => r.promise())
      );
      responses.forEach((response, index) => {
        if (response.status === "fulfilled" && response.value.data.length > 0) {
          this.postsEveryCategory.push({
            category: this.postCategories[index],
            posts: plainToClass(Post, response.value.data),
          });
        }
      });
    }
    console.log("this.postCategories", this.postCategories);
    console.log("this.postsEveryCategory", this.postsEveryCategory);
  }
  async mounted() {
    if (document) {
      this.currentWidthWindow = document.body.offsetWidth;
    }
    window.addEventListener("resize", this.checkResizeScreen);

    if (this.currentTab == "") this.currentTab = "ALL";
    if (this.$route.hash) {
      this.currentTab = decodeURI(this.$route.hash.slice(1));
    }
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.checkResizeScreen);
  }

  checkResizeScreen(event: UIEvent) {
    if (event !== null) {
      this.currentWidthWindow = Number((event.target as Window).innerWidth);
    }
  }

  @Watch("currentTab")
  fetchDataByCategory(newVal: string, oldVal: string) {
    if (newVal != oldVal) {
      if (this.currentTab === "ALL") {
        this.currenBaseUrl = `api/v1/blogs`;
        this.optionParam = this.$auth.loggedIn
          ? ``
          : `language_code=${this.$i18n.locale}`;
        this.currentCategory = new PostCategory();
      } else {
        this.currentCategory = (this as any).postCategories.filter(
          (cat) => cat.categoryName === decodeURIComponent(this.currentTab)
        )[0];
        this.currenBaseUrl = `api/v1/categories/${
          this.currentCategory!.id
        }/blogs`;
        this.optionParam = this.$auth.loggedIn
          ? ``
          : `language_code=${this.$i18n.locale}`;
      }
      if (this.posts.length > 0) {
        this.posts.length = 0;
      }
      this.scrollToTopPage();
    }
  }
  @Watch("$route.hash")
  onRoueHashChanged(newVal: string, oldVal: string) {
    if (newVal != oldVal) {
      this.updateTabBaseOnRouteHash(newVal);
    }
  }
  scrollToTopPage() {
    if (process.client) {
      this.$nextTick(() => {
        if (
          this.currentTab == "ALL" &&
          this.$route.hash === "#ALL" &&
          this.posts.length > 4
        ) {
          const yOffset = this.$device.isMobile ? -132 : -180;
          const element = this.$refs["all-article"] as any;
          const y =
            element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y });
        } else {
          window.scrollTo({ top: 0 });
        }
      });
    }
    this.isLoading = true;
  }
  parseDataClass(result: []) {
    (this as any).posts = plainToClass(Post, result);
  }

  updateTabBaseOnRouteHash(hash: string) {
    if (hash !== "") {
      this.currentTab = decodeURIComponent(hash.slice(1));
    } else this.currentTab = "ALL";
  }
  linkNextTab = "";
  updateRouteHashBaseOnTab(nextTab: string) {
    let nextHash: string = `#${nextTab}`;
    this.linkNextTab = (this as any).localePath({
      name: "posts",
      hash: nextHash,
    });
    this.$router.push(this.linkNextTab);
    window.scrollTo(0, 0);
  }

  routeHashBaseOnTab(nextHash: string) {
    return (this as any).localePath({
      name: "posts",
      hash: "#" + nextHash,
    });
  }
  findNameByLanguageSetting(_arrayInput, tempLang: string) {
    const lang = tempLang ? tempLang : this.$i18n.locale;
    let tmpObject = _arrayInput.find((o1) => o1.language_code === lang);
    if (tmpObject) {
      return tmpObject.name;
    }
    return {
      name: "",
    };
  }
  onGoToPostDetail(post) {
    return (this as any).localePath({
      name: "posts-slug",
      params: {
        slug: this.$formatSlugUrl(
          post.title || "",
          this.$fromUUID(post.id || "")
        ),
      },
    });
  }
  getNameCategory(id) {
    try {
      const element = document.getElementById(this.currentTab);
      if (element) {
        element.scrollIntoView();
      }
    } catch (e) {}
    let obj = this.postCategories
      ?.find((_cat) => _cat.id === id)
      ?.names?.find((_n) => (_n as any).language_code === this.$i18n.locale);
    if (obj) return (obj as any).name;
    return this.postCategories!.find((_cat) => _cat.id === id)?.categoryName;
  }
  get filteredHashtag() {
    return this.filteredArrayLanguageCode(
      this.cleanSource(this.popularHashtags),
      this.$i18n.locale
    );
  }
  gotoSearchHashtag(hashtag) {
    return (this as any).localePath({
      name: "posts-search",
      query: { hashtag_ids: hashtag.id, hashtag_name: hashtag.name },
    });
  }
}
